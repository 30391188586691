import PropTypes from 'prop-types'
import { isEmpty } from '@/lib/lodash'
import { isBoolean } from '@/lib/lodash'

import { ErrorMessage } from 'formik'

const ref = (obj, str) => {
  str = str.split('.')
  for (var i = 0; i < str.length; i++) {
    obj = obj[str[i]] ? obj[str[i]] : {}
  }
  return isBoolean(obj) ? obj : isEmpty(obj) ? false : true
}

const isInvalid = (form, field) => {
  if (form) {
    if (!isEmpty(form.touched) && !isEmpty(form.errors)) {
      if (ref(form.touched, field.name) && ref(form.errors, field.name)) {
        return true
      }
      return false
    }
  }

  return false
}

const CustomInput = ({ field, form, ...props }) => {
  return (
    <>
      <label
        htmlFor={props.id}
        className={`custom-input bg-white px-4 py-4 relative border ${isInvalid(form, field) ? 'border-red-500' : ''}`}
      >
        <input
          type={props.type}
          id={props.id}
          disabled={props.disabled}
          name={props.name}
          placeholder={props.placeholder}
          {...field}
          className="w-full outline-none text-base md:text-sm focus:outline-none"
        />
      </label>
      {field && (
        <ErrorMessage
          component="div"
          name={field.name}
          className="flex items-center font-medium tracking-wide text-red-500 text-xs ml-1"
        />
      )}
      <style jsx>{`
        .custom-input {
          border-radius: 0px;
          overflow: hidden;
          position: relative;
        }

        .custom-input .label-wrap {
          pointer-events: none;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          transition: all 0.1s ease;
        }

        .custom-input input:not(:placeholder-shown) + .label-wrap {
          font-size: 10px;
          transform: translatey(-18px);
        }

        .custom-input input:focus + .label-wrap {
          font-size: 10px;
          transform: translatey(-18px);
        }

        .custom-input input:not(:placeholder-shown) + .label-wrap .label-example,
        .custom-input input:focus + .label-wrap .label-example {
          opacity: 0;
        }
      `}</style>
    </>
  )
}

CustomInput.defaultProps = {
  placeholderExample: null,
}

CustomInput.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderExample: PropTypes.string,
}

export default CustomInput
