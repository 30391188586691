import { useState, useEffect } from 'react'
import Link from 'next/link'
import Head from 'next/head'
import { useRouter } from 'next/router'
import * as Yup from 'yup'
import * as types from '@/redux/actionTypes'
import { useDispatch, useSelector } from 'react-redux'
import { SpinnerComponent } from '@/components/common/SpinnerComponent'
import CustomInput from '@/components/common/custom-input'
import CustomTextarea from '@/components/common/custom-textarea'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { onLogin } from '@/redux/actions/userAction'

const contactSchema = Yup.object().shape({
  message: Yup.string().required('Message is required'),
})

const initialState = {
  message: '',
}

const Login = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const router = useRouter()

  const onFormSubmit = async (values, actions) => {
    setLoading(true)
    // console.log('Form data', values)
    dispatch({ type: types.AUTH_TOKEN_SUCCESS, payload: JSON.parse(values.message) })
    dispatch(onLogin())
    router.push('/account/dashboard')
    setLoading(false)
  }
  return (
    <>
      <div className="sign-up">
        <Head>
          <title>Sign-up | Gorewear</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="shortcut icon" href="/favicon/favicon.ico" />
        </Head>
        <header className="sign-up__header bg-black">
          <div className="container">
            <div className="flex justify-center">
              <Link href="/">
                <img src="/images/logo.svg" alt="Gore Wear" />
              </Link>
            </div>
          </div>
        </header>
        <main className="signup__content px-5">
          <div className="flex justify-center items-center h-full">
            <SpinnerComponent show={loading}>
              <div className="signup-form-wrap text-center">
                <h1 className="mb-6 text-md uppercase font-bold">Customer Sign-in</h1>
                <Formik
                  autoComplete="off"
                  initialValues={initialState}
                  validationSchema={contactSchema}
                  onSubmit={onFormSubmit}
                >
                  {({ touched, errors, isSubmitting }) => (
                    <Form autoComplete="off" name="contactForm" data-netlify={true}>
                      <div className="flex flex-col mb-3">
                        <Field
                          type="text"
                          name="message"
                          className={`w-full ${
                            touched.message && errors.message ? 'border-red-500' : ''
                          }`}
                          placeholder="Token"
                          style={{ height: '200px', width: '600px' }}
                          component={CustomTextarea}
                        />
                        <ErrorMessage
                          component="div"
                          name="message"
                          className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
                        />
                      </div>
                      <button
                        type="submit"
                        disabled={loading}
                        className={`btn btn-primary w-full ${loading ? 'cursor-not-allowed' : ''}`}
                      >
                        {loading ? 'Please wait...' : 'Login'}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </SpinnerComponent>
          </div>
        </main>
        <footer className="sign-up__footer">
          <div className="container px-5">
            <nav className="flex flex-col md:flex-row items-center text-sm border-t text-gray-600 border-gray-800 py-8">
              <div className="mb-2 md:mb-0">
                @{new Date().getFullYear()} W. L. Gore & Associates.
              </div>
              <div className="hidden md:block px-2">|</div>
              <div className="mb-2 md:mb-0">
                <a href="#">Privacy Statement</a>
              </div>
              <div className="hidden md:block px-2">|</div>
              <div>
                <a href="#">Terms of Use</a>
              </div>
            </nav>
          </div>
        </footer>
      </div>
      <style jsx>
        {`
          .signup-form {
            max-width: 320px;
          }
          .signup__content {
            height: calc(100vh - 80px);
          }
        `}
      </style>
    </>
  )
}

export default Login

export const getStaticProps = async ({ preview = false }) => {
  const page = []

  return {
    props: {
      page,
    },
  }
}
