import PropTypes from 'prop-types';

const CustomTextarea = ({ field, form, ...props }) => {

  return (
    <>
      <label htmlFor={ props.id} className={`custom-textarea px-4 pb-2 pt-5 relative border border-gray-200 ${props.className}`} style={ props.style }>
        <textarea
          id={ props.id }
          name={ props.name }
          placeholder="&nbsp;"
          {...field}
          className="w-full h-full outline-none text-sm resize-none focus:outline-none"
        ></textarea>
        <div className="label-wrap flex w-full left-0 text-sm px-4 text-gray-600">
          <span className="label">{ props.placeholder } </span>
          {
            (props.placeholderExample != null) &&
            <span className="label-example absolute right-0 pr-4">{ props.placeholderExample }</span>
          }

        </div>
      </label>
      <style jsx>{
      `
        .custom-textarea {
          border-radius: 3px;
          position: relative;
        }

        .custom-textarea .label-wrap {
          pointer-events: none;
          position: absolute;
          top: 25px;
          transform: translateY(-50%);
          transition: all 0.1s ease;
        }

        .custom-textarea textarea:not(:placeholder-shown) + .label-wrap {
          font-size: 10px;
          transform: translatey(-18px);
        }

        .custom-textarea textarea:focus + .label-wrap {
          font-size: 10px;
          transform: translatey(-18px);
        }

        .custom-textarea textarea:not(:placeholder-shown) + .label-wrap .label-example,
        .custom-textarea textarea:focus + .label-wrap .label-example {
          opacity: 0;
        }
      `
      }</style>
    </>
  );
}

CustomTextarea.defaultProps = {
  placeholderExample: null
}

CustomTextarea.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderExample: PropTypes.string
}

export default CustomTextarea;